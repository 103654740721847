<template>
  <div>
    <!-- banner -->
    <div class="banner" :style="cover">
      <h1 class="banner-title">{{ videoClassifyName }}</h1>
    </div>
    <!-- 视频分类列表 -->
    <v-card class="blog-container">
      <div class="photo-wrap" >
        <div  v-for="(item, index) of videoList" style="display: flex;flex-direction: column;align-items: center">
          <video class="video-js" :src="item.url" :key="index" controls="controls"></video>
          <div>{{item.name}}</div>
        </div>
      </div>
      <!-- 无限加载 -->
      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </v-card>
  </div>
</template>

<script>
import { getVideos } from "../../api";

export default {
  metaInfo:{
    meta: [{
      name: 'keyWords',
      content: "若离,www.ruolikongjian.cn,博客,个人博客,开源博客"  //变量或字符串
    }, {
      name: 'description',
      content: "一个专注于技术分享的博客平台,大家以共同学习,乐于分享,拥抱开源的价值观进行学习交流"
    }]
  },
  data: function() {
    return {
      videoClassifyName: "",
      videoClassifyCover: "",
      videoList: [],
      current: 1,
      size: 12
    };
  },
  methods: {
    preview(index) {
      this.$imagePreview({
        images: this.videoList,
        index: index
      });
    },
    infiniteHandler($state) {
      getVideos(
        {
          videoClassifyId: this.$route.params.videoClassifyId,
          pageNo: this.current,
          pageSize: this.size
        }).then(res => {
          this.videoClassifyCover = res.data.videoClassifyCover;
          this.videoClassifyName = res.data.videoClassifyName;
          if (res.data.videoList.length) {
            this.current++;
            this.videoList.push(...res.data.videoList);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    }
  },
  computed: {
    cover() {
      return (
        "background: url(" +
        this.videoClassifyCover +
        ") center center / cover no-repeat"
      );
    }
  }
};
</script>

<style scoped>
.photo-wrap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.photo {
  margin: 3px;
  cursor: pointer;
  flex-grow: 1;
  object-fit: cover;
  height: 200px;
}

@media (max-width: 759px) {
  .photo {
    width: 100%;
  }
}
</style>
